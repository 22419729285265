import '../../../css/app.css';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import '../../sass/metrica/app.scss';
import '../../sass/eventbooks/app.scss';
import '../../sass/eventbooks/minimal.scss';

if (['production', 'staging'].includes(document.querySelector('meta[name="sentry-environment"]')?.content)) {
    const propagationTargets = document
        .querySelector('meta[name="sentry-propagation-targets"]')
        .content.split(',')
        .map((r) => new RegExp(r));

    Sentry.init({
        Vue,
        dsn: document.querySelector('meta[name="sentry-public-dsn"]').content,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: document.querySelector('meta[name="sentry-environment"]').content,
        release: document.querySelector('meta[name="sentry-release"]').content,
        tracesSampleRate: document.querySelector('meta[name="sentry-sample-rate"]').content,
        tracePropagationTargets: propagationTargets,
        replaysSessionSampleRate: document.querySelector('meta[name="sentry-replay-sample-rate"]').content,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, hint) {
            if (hint?.originalException?.message?.startsWith('sentryIgnore-')) {
                return null;
            }

            return event;
        },
    });
}

import { DateTime, Interval } from 'luxon';
window.DateTime = DateTime;
window.Interval = Interval;

window.Eventbus = new Vue();
import axios from 'axios';

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            throw error;
        }
        if (error.response.status === 422) {
            if (error.response.data && error.response.data.errors) {
                const errors = Object.values(error.response.data.errors).join('\n');
                swal.fire('Error', errors, 'error');
                return Promise.reject(new Error('sentryIgnore-' + errors));
            }
        } else if (error.response.status === 500) {
            swal.fire(
                'Internal Server Error',
                "An internal server error has occurred and we're unable to process your request. We have been automatically notified of this error.",
                'error'
            );
            return Promise.reject(error);
        } else if (error.response.status === 403) {
            swal.fire('Forbidden', error.response.data.message, 'error');
            return Promise.reject(error);
        }
        throw error;
    }
);

window.axios = axios;

/**
 * Eventbooks javascript
 */
import '../synergitech/helpers/localeFormatting';

import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

import * as DateFns from 'date-fns';
window.dateFns = DateFns;
const { format, formatDistance } = DateFns;

window.toast = (title, content, type = 'info', subtitle = '') =>
    dispatchEvent(
        new CustomEvent('toast', {
            detail: {
                title,
                content,
                type,
                subtitle,
            },
        })
    );

import VueFlatPickr from 'vue-flatpickr-component';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
window.monthSelectPlugin = MonthSelectPlugin;
import VueApexCharts from 'vue-apexcharts';
import Buefy from 'buefy';
import { createPinia, PiniaVuePlugin } from 'pinia';

const pinia = createPinia();

Vue.use(PiniaVuePlugin);
Vue.use(Buefy);
Vue.use(VueFlatPickr);
Vue.use(VueApexCharts);

Vue.prototype.localise = localise;
Vue.prototype.eventCurrencySymbol = eventCurrencySymbol;
if (typeof eventCurrencyCode !== 'undefined') {
    Vue.prototype.eventCurrencyCode = eventCurrencyCode;
}
if (typeof Auth !== 'undefined') {
    Vue.prototype.Auth = Auth;
}
if (typeof route !== 'undefined') {
    Vue.prototype.route = route;
}
Vue.prototype.toast = toast;
Vue.prototype.DateTime = DateTime;
Vue.prototype.setTitle = (title = null, appName = 'Eventwise') => {
    document.title = (title ? title + ' | ' : '') + appName;
};

Vue.filter('formatLongDate', function (value) {
    if (value) {
        return new Date(value).toDateString();
    }
});

Vue.filter('alphanumeric', function (value) {
    if (value) {
        return value.replace(/[^a-zA-Z0-9]/, '');
    }
});

Vue.filter('numeric', function (value) {
    if (value) {
        return value.replace(/[^0-9\.]/, '');
    }
});

Vue.filter('monetary', function (value) {
    if (value) {
        return parseFloat(value.toString().replace(/[^0-9\.]/, '')).toFixed(2);
    }
    const missingValue = '0.0000';
    return parseFloat(missingValue).toFixed(2);
});

Vue.filter('purchaseorder', function (value) {
    //"%04d"|format(purchaseorder.po_number)
    return value.toString().padStart(4, '0');
});

Vue.filter('percentage', function (value = 0, decimals = 0) {
    // value = value * 100
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    return value + '%';
});

Vue.filter('truncate', function (text, length, clamp) {
    clamp = clamp || '...';
    let node = document.createElement('div');
    node.innerHTML = text;
    let content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.mixin({
    methods: {
        bytes2kb(bytes) {
            return (parseFloat(bytes) / 1000).toFixed(1);
        },
        bytes2mb(bytes) {
            return (parseFloat(this.bytes2kb(bytes)) / 1000).toFixed(1);
        },
        obj2form(object) {
            let formData = new FormData();
            Object.keys(object).forEach((key) => {
                if (Array.isArray(object[key])) {
                    object[key].forEach((item, index) => {
                        if (!Array.isArray(item) && !(item instanceof File) && typeof item === 'object') {
                            Object.keys(item).forEach((itemKey) => {
                                formData.append(
                                    key + '[' + index + '][' + itemKey + ']',
                                    item[itemKey] === null ? '' : item[itemKey]
                                );
                            });
                            return;
                        }
                        formData.append(key + '[]', item === null ? '' : item);
                    });
                    return;
                } else if (object[key] && typeof object[key] === 'object') {
                    Object.keys(object[key]).forEach((objectKey) => {
                        formData.append(
                            key + '[' + objectKey + ']',
                            object[key][objectKey] === null ? '' : object[key][objectKey]
                        );
                    });
                    return;
                }
                formData.append(key, object[key] === null ? '' : object[key]);
            });
            return formData;
        },
        pushState(path, data = {}, preserveQueryParams = true) {
            window.history.pushState(data, '', path + (preserveQueryParams ? window.location.search : ''));
        },
        arraySum(arr, key) {
            return arr.reduce((acca, value) => acca + value[key], 0);
        },
        arrayUnique(arr, key = null) {
            if (key) {
                arr = arr.map((i) => i[key]);
            }

            return arr.filter((v, i, a) => {
                return a.indexOf(v) === i;
            });
        },
        gross2net(grossValue, taxRate) {
            return (grossValue / (100 + taxRate)) * 100;
        },
        net2gross(netValue, taxRate) {
            return netValue + (netValue / 100) * taxRate;
        },
        randomString(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        },
        ordinalSuffix(i) {
            let j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + 'st';
            }
            if (j == 2 && k != 12) {
                return i + 'nd';
            }
            if (j == 3 && k != 13) {
                return i + 'rd';
            }
            return i + 'th';
        },
        djb2(str) {
            // This method uses djb2 to convert a string to a unique hash
            let hash = 5381;
            for (let i = 0; i < str.length; i++) {
                hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
            }
            return hash;
        },
        hashStringToColour(str) {
            let hash = this.djb2(str);
            let r = (hash & 0xff0000) >> 16;
            let g = (hash & 0x00ff00) >> 8;
            let b = hash & 0x0000ff;
            return (
                '#' +
                ('0' + r.toString(16)).substr(-2) +
                ('0' + g.toString(16)).substr(-2) +
                ('0' + b.toString(16)).substr(-2)
            );
        },
        contrastingTextColour(bgColor, lightColor, darkColor) {
            let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
            let r = parseInt(color.substring(0, 2), 16); // hexToR
            let g = parseInt(color.substring(2, 4), 16); // hexToG
            let b = parseInt(color.substring(4, 6), 16); // hexToB
            let uicolors = [r / 255, g / 255, b / 255];
            let c = uicolors.map((col) => {
                if (col <= 0.03928) {
                    return col / 12.92;
                }
                return Math.pow((col + 0.055) / 1.055, 2.4);
            });
            let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
            return L > 0.179 ? darkColor : lightColor;
        },
        setRoute(route, params = {}, defaults = ['account', 'event'], preserveQueryParams = true) {
            const ziggyRoute = Ziggy.routes[route];
            if (!ziggyRoute) {
                // eslint-disable-next-line no-console
                console.error('Unknown route provided to setRoute');
                return;
            } else if (defaults.length) {
                defaults.forEach((defaultParam) => {
                    if (
                        window.route().params[defaultParam] &&
                        !params[defaultParam] &&
                        Object.keys(ziggyRoute.bindings).includes(defaultParam)
                    ) {
                        params[defaultParam] = window.route().params[defaultParam];
                    }
                });
            }
            let uri = '/' + ziggyRoute.uri;
            Object.keys(params).forEach((param) => {
                uri = uri.replaceAll(`{${param}}`, params[param]);
                uri = uri.replaceAll(`{${param}?}`, params[param]);
            });
            // Replace any remaining optional parameters that have not been provided
            uri = uri.replaceAll(/ *\{[^}]*\?} */gi, '');
            uri = uri.replaceAll(/\/\/+/g, '/');
            if (preserveQueryParams) {
                uri = uri + window.location.search;
            }
            const routeData = {
                route: route,
                uri: uri,
                params: params,
            };
            history.pushState(routeData, '', uri);
            window.Eventbus.$emit('routeChanged', routeData);
            window.Eventbus.$emit('pageChanged');
        },
        flushPageEvents() {
            if (window.Eventbus._events.pageChanged) {
                delete window.Eventbus._events.pageChanged;
            }
        },
        isValidEmail(email) {
            return !!String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        localTime(value, isoMode = true) {
            const dt = isoMode
                ? DateTime.fromISO(value, {
                      zone: 'utc',
                  })
                : DateTime.fromSQL(value, {
                      zone: 'utc',
                  });
            return dt.toLocal();
        },
        localTimestamp(value, isoMode = true) {
            return this.localTime(value, isoMode).toLocaleString(DateTime.DATETIME_MED);
        },
        relativeTimestamp(value, zone = 'utc', isoMode = true) {
            const relativeStamp = isoMode
                ? DateTime.fromISO(value, {
                      zone: zone,
                  }).toRelative()
                : DateTime.fromSQL(value, {
                      zone: zone,
                  }).toRelative();
            return relativeStamp == '0 seconds ago' ? 'now' : relativeStamp;
        },
        dropGetParameter(param) {
            const url = new URL(window.location.href);
            url.searchParams.delete(param);
            window.history.replaceState({}, '', url);
        },
        timeAgo(value) {
            return formatDistance(new Date(value), new Date(), {
                addSuffix: true,
            });
        },
        formatDate(value, dateFormat = 'MM/dd/yyyy HH:mm') {
            return format(new Date(value), dateFormat) + ' UTC';
        },
        capitalise(value) {
            return value
                .split(' ')
                .map((element) => {
                    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
                })
                .join(' ');
        },
        ucwords(value) {
            let arr = value.split(/_| /);
            let str = '';
            arr.forEach(function (v) {
                str += v.charAt(0).toUpperCase() + v.slice(1, v.length) + ' ';
            });
            return str;
        },
        initials(value) {
            if (value) {
                const initials = value.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
                return initials.join('');
            }
        },
        splitOnCapitals(value) {
            value = value.split(/(?=[A-Z])/);
            return value.join(' ');
        },
        filesize(value) {
            if (value) {
                // value must be in KB
                if (value >= 1024000000) {
                    // Greater than 1GB - display in GB
                    return parseFloat(value / 1024000000).toFixed(1) + 'GB';
                } else if (value >= 1024000) {
                    // Greater than 1MB - display in MB
                    return parseFloat(value / 1024000).toFixed(1) + 'MB';
                }
                // display in KB
                return parseFloat(value / 1024).toFixed(1) + 'KB';
            }
        },
    },
});

Vue.component('App', () => import('../../../js/components/App.vue'));
Vue.component('twofactorauthenticate', () => import('../../../js/components/TwoFactorAuthenticate.vue'));
Vue.component('invitationaccept', () => import('../../../js/components/InvitationAccept.vue'));

Vue.component('v-select', () => import('vue-select'));

Vue.component('page-admin', () => import('../../../js/components/pages/Admin.vue'));

Vue.component('global-notifications', () => import('./components/utility/global-notifications.vue'));

Vue.component('input-edit', () => import('./components/input-edit.vue'));

Vue.component('ew-card', () => import('./components/templates/_card.vue'));
Vue.component('ew-modal', () => import('./components/templates/_modal.vue'));
Vue.component('ew-files', () => import('./components/utility/widgets/files.vue'));

Vue.component('approvals-row', () => import('./components/approvals/row.vue'));

Vue.component('budget-approvals-card', () => import('./components/budget/approvals/card.vue'));

Vue.component('drawer-invoices-tab', () => import('./components/drawer-tabs/invoices.vue'));
Vue.component('drawer-budgeting-tab', () => import('./components/drawer-tabs/budgeting.vue'));
Vue.component('drawer-files-tab', () => import('./components/drawer-tabs/files.vue'));
Vue.component('drawer-purchase-orders-tab', () => import('./components/drawer-tabs/purchase-orders.vue'));
Vue.component('drawer-comments-tab', () => import('./components/drawer-tabs/comments.vue'));
Vue.component('drawer-activity-tab', () => import('./components/drawer-tabs/activity.vue'));
Vue.component('drawer-adjustment-tab', () => import('./components/drawer-tabs/adjustment.vue'));
Vue.component('drawer-extra-info-tab', () => import('./components/drawer-tabs/extra-info.vue'));

Vue.component('control-addresses', () => import('./components/utility/controls/addresses.vue'));
Vue.component('control-currency', () => import('./components/utility/controls/currency.vue'));
Vue.component('control-currencies', () => import('./components/utility/controls/currencies.vue'));
Vue.component('control-icon', () => import('./components/utility/controls/icon.vue'));
Vue.component('control-select', () => import('./components/utility/controls/select/inline.vue'));
Vue.component('control-select-two-way', () => import('./components/utility/controls/select/two-way.vue'));
Vue.component('control-stream-type', () => import('./components/utility/controls/stream-type.vue'));
Vue.component('control-uploader', () => import('./components/utility/controls/uploader.vue'));
Vue.component('figures-card', () => import('./components/utility/controls/figures-card.vue'));

Vue.component('form-socialite-invite-code', () => import('./components/utility/forms/invite-code.vue'));
Vue.component('modal-selector', () => import('./components/modals/selector.vue'));
Vue.component('modal-select-supplier', () => import('./components/modals/selectors/supplier-selector.vue'));

Vue.component('onboarding-xero-multi-tenant-selector', () => import('./components/setup/xero-tenant-selector.vue'));
Vue.component('onboarding-add-department-modal', () => import('./components/onboarding/modals/add-department.vue'));
Vue.component(
    'onboarding-manage-categories-modal',
    () => import('./components/onboarding/modals/manage-categories.vue')
);

Vue.component('table-budget-entries-estimated', () => import('./components/tables/budget-entries-estimated.vue'));
Vue.component('table-departments', () => import('./components/tables/departments-table.vue'));
Vue.component('table-categories', () => import('./components/tables/categories-table.vue'));
Vue.component('table-payruns', () => import('./components/tables/purchasing/payruns.vue'));
Vue.component('table-scenario-adjustments', () => import('./components/tables/scenario-adjustments-table.vue'));

Vue.component('widget-user-avatar', () => import('./components/utility/widgets/user/avatar.vue'));
Vue.component('widget-user-inline', () => import('./components/utility/widgets/user/inline.vue'));
Vue.component('widget-avatar-list', () => import('./components/utility/widgets/avatar-list.vue'));

Vue.component('activity', () => import('./components/utility/activity.vue'));

Vue.component('purchasing-approvals-card', () => import('./components/purchasing/approvals/card.vue'));

Vue.component('organisation-modal-address', () => import('./components/modals/organisation/address.vue'));

Vue.component('address-form', () => import('./components/forms/address.vue'));

Vue.component('auth-login', () => import('./components/auth/login.vue'));
Vue.component('reset-password', () => import('./components/utility/forms/reset-password.vue'));

for (const component of Object.keys(Vue.options.components)) {
    for (const el of document.querySelectorAll(component)) {
        new Vue({
            el: el,
            name: component,
            pinia,
        });
    }
}
